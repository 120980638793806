// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


require('jquery')
import('src/add-eu-banner')
import('src/plugins')  // loads popper.js and bootstrap async
import('src/linkfix')  // loads linkfix scripts async
import('src/navigation')  // loads navigation scripts async

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import('src/add-eu-banner')
require('css/site')




require.context('../images', true)

//import('src/js/script')
//const Globals = from 'src/js/script'
import('src/js/src/plugins/owlCarousel')


//<!-- Popper 1.16.0 via CDN, needed for Bootstrap Tooltips & Popovers -->
//<!-- script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script -->

/*
<!-- JS plugins required on all pages NOTE: Additional non-required plugins are loaded ondemand as of AppStrap 2.5 To disable this and load plugin assets manually simple add data-plugins-manual=true to the body tag -->

<!--Custom scripts & AppStrap API integration -->
<!-- script src="assets/js/custom-script.js"></script -->
<!--AppStrap scripts mainly used to trigger libraries/plugins -->
<script src="assets/js/script.min.js"></script>
*/
